import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  SuperBlockStages,
  SuperBlocks,
  superBlockOrder,
} from "../../../../shared/config/superblocks";
import { SuperBlockIcon } from "../../assets/icons/superblock-icon";
import LinkButton from "../../assets/icons/link-button";
import { Link, Spacer } from "../helpers";
import { getSuperBlockTitleForMap } from "../../utils/superblock-map-titles";
import { getSuperBlockIntroForMap } from "../../utils/superblock-map-intro";
import { showUpcomingChanges } from "../../../config/env.json";
import "./map.css";
import Login from "../Header/components/login"; // Import the login component
import LoginToSee from "../Header/components/logintosee"; // Ensure the path is correct
import { useSelector, useDispatch } from "react-redux"; // Add useSelector and useDispatch to access Redux state
import { isSignedInSelector } from "../../redux/selectors";
import WebDesing from './web_desing'
import DataDev from './data_dev'
interface MapProps {
  forLanding?: boolean;
}

const linkSpacingStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
};

const coreCurriculum = [
  ...superBlockOrder[SuperBlockStages.FrontEnd],
  ...superBlockOrder[SuperBlockStages.Backend],
  // Uncomment when needed
  // ...superBlockOrder[SuperBlockStages.Python],
];

function MapLi({
  superBlock,
  landing = false,
}: {
  superBlock: SuperBlocks;
  landing: boolean;
}) {
  const isSignedIn = useSelector(isSignedInSelector); // Get sign-in status from Redux
  const superBlockTitle = getSuperBlockTitleForMap(superBlock); // Get the title for the superBlock

  const isA2English =
    superBlockTitle === "A2 English for Developers Certification";
  const isWebDesign = superBlockTitle === "Responsive Web Design Certification";
  const isFrontEnd =
    superBlockTitle === "Front End Development Libraries Certification";
  const isBackEnd =
    superBlockTitle === "Back End Development and APIs Certification";
  const isDataVis = superBlockTitle === "Data Visualization Certification";
  const isRelationalDatabase =
    superBlockTitle === "Relational Database Certification";
  const isDataAnalysis =
    superBlockTitle === "Data Analysis with Python Certification";

 

  return (
    <li
      data-test-label="curriculum-map-button"
      className={`${isA2English ? "red-background" : "card"}`}
    >
      {isSignedIn ? (
        <Link className="card-link" to={`/learn/${superBlock}/`}>
          <div
            className={`${
              isA2English ? "card-container-red" : "card-container"
            }`}
          >
            <SuperBlockIcon className="map-icon" superBlock={superBlock} />
            <div className="card-body">
              <div className="card-title">{superBlockTitle}</div>
              <p className="card-text">
                {getSuperBlockIntroForMap(superBlock)}
              </p>
              <button
                className={`${
                  isA2English ? "details-button-red" : "details-button"
                }`}
              >
                See More Details
              </button>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={`${isA2English ? "card-container-red" : "card-container"}`}
        >
          <SuperBlockIcon className="map-icon" superBlock={superBlock} />
          <div className="card-body">
            <div
              className={`card-title ${isA2English ? "red-background" : ""}`}
            >
              {superBlockTitle}
            </div>
            <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
            {/* Show login button which opens the Login modal or redirect to login page */}
            <button
              className={`${
                isA2English ? "details-button-red" : "details-button"
              }`}
            >
              <LoginToSee />
            </button>
          </div>
        </div>
      )}
    </li>
  );
}



function Map({ forLanding = false }: MapProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className="map-ui " data-test-label="curriculum-map">
  
      <Spacer size="medium" />
      <Spacer size="medium" />
      <div className="english-comp">
    
        <ul
          className={`${
            superBlockOrder[SuperBlockStages.English].some(
              (superBlock) =>
                getSuperBlockTitleForMap(superBlock) ===
                "A2 English for Developers Certification"
            )
              ? "english-ul"
              : ""
          }`}
        >
          {superBlockOrder[SuperBlockStages.English].map((superBlock, i) => (
            <MapLi key={i} superBlock={superBlock} landing={forLanding} />
          ))}
        </ul>
      </div>
      <Spacer size="medium" />
      <Spacer size="medium" />

      {/* <section className=" container">
        <div className="map-ui" data-test-label="curriculum-map">
         

          <ul>
            {coreCurriculum.map((superBlock, i) => (
              <MapLi key={i} superBlock={superBlock} landing={false} />
            ))}
          </ul>

          <Spacer size="medium" />
        </div>
      </section> */}
      <Spacer size="medium" />
      <Spacer size="medium" />
      <WebDesing />
      <Spacer size="medium" />
      <DataDev />
      {showUpcomingChanges && (
        <>
          <h2 className="content-title-cards">
            {t("landing.upcoming-heading")}
          </h2>
          <ul>
            {superBlockOrder[SuperBlockStages.Upcoming].map((superBlock, i) => (
              <MapLi key={i} superBlock={superBlock} landing={forLanding} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

Map.displayName = "Map";

export default Map;
