import React, { useRef,useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { Container } from "@freecodecamp/ui";
import imgProfile from "./profile.png";

import Camper from "../components/profile/components/camper";

import store from "store";
import envData from "../../config/env.json";
import { createFlashMessage } from "../components/Flash/redux";
import { Loader, Spacer } from "../components/helpers";
import Certification from "../components/settings/certification";
import About from "../components/settings/about";
import DangerZone from "../components/settings/danger-zone";
import Email from "../components/settings/email";
import Honesty from "../components/settings/honesty";
import Internet, { Socials } from '../components/settings/internet';

import Portfolio from '../components/settings/portfolio';
import Privacy from "../components/settings/privacy";
import { type ThemeProps, Themes } from "../components/settings/theme";
import UserToken from "../components/settings/user-token";
import { hardGoTo as navigate } from "../redux/actions";
import ShowCertification from "./show-certification";
import Profile from "../components/profile/profile";

import {
  signInLoadingSelector,
  userSelector,
  isSignedInSelector,
  userTokenSelector,
} from "../redux/selectors";
import { User } from "../redux/prop-types";
import {
  submitNewAbout,
  updateMyHonesty,
  updateMyPortfolio,
  updateMyQuincyEmail,
  updateMySocials,
  updateMySound,
  updateMyTheme,
  updateMyKeyboardShortcuts,
  verifyCert,
} from "../redux/settings/actions";
const { apiLocation } = envData;

// TODO: update types for actions
type ShowSettingsProps = Pick<ThemeProps, "toggleNightMode"> & {
  createFlashMessage: typeof createFlashMessage;
  isSignedIn: boolean;
  navigate: (location: string) => void;
  showLoading: boolean;
  submitNewAbout: () => void;
  toggleSoundMode: (sound: boolean) => void;
  toggleKeyboardShortcuts: (keyboardShortcuts: boolean) => void;
  updateSocials: (formValues: Socials) => void;
  updateIsHonest: () => void;
  updatePortfolio: () => void;
  updateQuincyEmail: (isSendQuincyEmail: boolean) => void;
  user: User;
  verifyCert: typeof verifyCert;
  path?: string;
  userToken: string | null;
};

const mapStateToProps = createSelector(
  signInLoadingSelector,
  userSelector,
  isSignedInSelector,
  userTokenSelector,
  (showLoading: boolean, user: User, isSignedIn, userToken: string | null) => ({
    showLoading,
    user,
    isSignedIn,
    userToken,
  })
);

const mapDispatchToProps = {
  createFlashMessage,
  navigate,
  submitNewAbout,
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  toggleSoundMode: (sound: boolean) => updateMySound({ sound }),
  toggleKeyboardShortcuts: (keyboardShortcuts: boolean) =>
    updateMyKeyboardShortcuts({ keyboardShortcuts }),
  updateSocials: (formValues: Socials) => updateMySocials(formValues),
  updateIsHonest: updateMyHonesty,
  updatePortfolio: updateMyPortfolio,
  updateQuincyEmail: (sendQuincyEmail: boolean) =>
    updateMyQuincyEmail({ sendQuincyEmail }),
  verifyCert,
};

export function ShowSettings(props: ShowSettingsProps): JSX.Element {
  const { t } = useTranslation();
  const {
    createFlashMessage,
    isSignedIn,
    submitNewAbout,
    toggleNightMode,
    toggleSoundMode,
    toggleKeyboardShortcuts,
    user: {
      completedChallenges,
      email,
      is2018DataVisCert,
      isApisMicroservicesCert,
      isJsAlgoDataStructCert,
      isBackEndCert,
      isDataVisCert,
      isFrontEndCert,
      isInfosecQaCert,
      isQaCertV7,
      isInfosecCertV7,
      isFrontEndLibsCert,
      isFullStackCert,
      isRespWebDesignCert,
      isSciCompPyCertV7,
      isDataAnalysisPyCertV7,
      isMachineLearningPyCertV7,
      isRelationalDatabaseCertV8,
      isCollegeAlgebraPyCertV8,
      isFoundationalCSharpCertV8,
      isJsAlgoDataStructCertV8,
      isEmailVerified,
      isHonest,
      sendQuincyEmail,
      username,
      about,
      picture,
      theme,
      keyboardShortcuts,
      location,
      name,
      githubProfile,
      linkedin,
      twitter,
      website,
      portfolio,
      cohort
    },
    navigate,
    showLoading,
    updateQuincyEmail,
    updateSocials,
    updatePortfolio,
    updateIsHonest,
    verifyCert,
    userToken,
  } = props;
  const isSignedInRef = useRef(isSignedIn);

  if (showLoading) {
    return <Loader fullScreen={true} />;
  }

  if (!isSignedInRef.current) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }
  const sound = (store.get("fcc-sound") as boolean) ?? false;


  useEffect(() => {
    // Liste des certificats validés
    const validCertificates = [];

    // Vérifier chaque certificat et l'ajouter à la liste si valide
    if (is2018DataVisCert) validCertificates.push('2018 Data Visualization');
    if (isApisMicroservicesCert) validCertificates.push('APIs and Microservices');
    if (isJsAlgoDataStructCert) validCertificates.push('JavaScript Algorithms and Data Structures');
    if (isBackEndCert) validCertificates.push('Back End Development');
    if (isDataVisCert) validCertificates.push('Data Visualization');
    if (isFrontEndCert) validCertificates.push('Front End Development');
    if (isInfosecQaCert) validCertificates.push('Information Security and Quality Assurance');
    if (isQaCertV7) validCertificates.push('Quality Assurance v7');
    if (isInfosecCertV7) validCertificates.push('Information Security v7');
    if (isFrontEndLibsCert) validCertificates.push('Front End Libraries');
    if (isFullStackCert) validCertificates.push('Full Stack');
    if (isRespWebDesignCert) validCertificates.push('Responsive Web Design');
    if (isSciCompPyCertV7) validCertificates.push('Scientific Computing with Python v7');
    if (isDataAnalysisPyCertV7) validCertificates.push('Data Analysis with Python v7');
    if (isMachineLearningPyCertV7) validCertificates.push('Machine Learning with Python v7');
    if (isRelationalDatabaseCertV8) validCertificates.push('Relational Database v8');
    if (isCollegeAlgebraPyCertV8) validCertificates.push('College Algebra v8');
    if (isFoundationalCSharpCertV8) validCertificates.push('Foundational C# v8');
    if (isJsAlgoDataStructCertV8) validCertificates.push('JavaScript Algorithms and Data Structures v8');

    // Enregistrer le tableau des certificats dans le localStorage
    localStorage.setItem('validCertificates', JSON.stringify(validCertificates));
    localStorage.setItem('cohort',cohort);
  }, [User]);
  

  return (
    <>
      <Helmet title={`${t("buttons.settings")} | web4jobs.ma`} />
      <section className="container" style={{ padding: "0 10px" }}>
        <main>

          
          <Spacer size="large" />

        



          <img
            className="profile-image"
            src={`${picture ? imgProfile : imgProfile}`}
            alt=""
            srcset=""
          />

          <style>
            {`
                .profile-image{
                width:120px;
                height:auto;
                margin-inline:auto;
                }
                `}
          </style>
          <h1
            id="content-start"
            className="text-center"
            style={{ overflowWrap: "break-word" }}
            data-playwright-test-label="settings-heading"
          >
            @{username}
          </h1>
          <h2 className="text-center">{name}</h2>
          
          
           <div className="profile-links">
               <a href={linkedin} target="_blank">
               <svg width="35px" height="35px" viewBox="0 0 48 48"  fill="#432B84"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>linkedin</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <path d="M41,4.1H7A2.9,2.9,0,0,0,4,7V41.1A2.9,2.9,0,0,0,7,44H41a2.9,2.9,0,0,0,2.9-2.9V7A2.9,2.9,0,0,0,41,4.1Zm-25.1,34h-6v-19h6Zm-3-21.6A3.5,3.5,0,0,1,9.5,13a3.4,3.4,0,0,1,6.8,0A3.5,3.5,0,0,1,12.9,16.5ZM38,38.1H32.1V28.8c0-2.2,0-5-3.1-5s-3.5,2.4-3.5,4.9v9.4H19.6v-19h5.6v2.6h.1a6.2,6.2,0,0,1,5.6-3.1c6,0,7.1,3.9,7.1,9.1Z"></path> </g> </g> </g></svg>
               </a>
               <a href={githubProfile}  target="_blank">
               <svg width="30px" height="30px" viewBox="0 0 20 20" version="1.1"  fill="#432B84"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>github [#142]</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -7559.000000)" fill="#432B84"> <g id="icons" transform="translate(56.000000, 160.000000)"> <path d="M94,7399 C99.523,7399 104,7403.59 104,7409.253 C104,7413.782 101.138,7417.624 97.167,7418.981 C96.66,7419.082 96.48,7418.762 96.48,7418.489 C96.48,7418.151 96.492,7417.047 96.492,7415.675 C96.492,7414.719 96.172,7414.095 95.813,7413.777 C98.04,7413.523 100.38,7412.656 100.38,7408.718 C100.38,7407.598 99.992,7406.684 99.35,7405.966 C99.454,7405.707 99.797,7404.664 99.252,7403.252 C99.252,7403.252 98.414,7402.977 96.505,7404.303 C95.706,7404.076 94.85,7403.962 94,7403.958 C93.15,7403.962 92.295,7404.076 91.497,7404.303 C89.586,7402.977 88.746,7403.252 88.746,7403.252 C88.203,7404.664 88.546,7405.707 88.649,7405.966 C88.01,7406.684 87.619,7407.598 87.619,7408.718 C87.619,7412.646 89.954,7413.526 92.175,7413.785 C91.889,7414.041 91.63,7414.493 91.54,7415.156 C90.97,7415.418 89.522,7415.871 88.63,7414.304 C88.63,7414.304 88.101,7413.319 87.097,7413.247 C87.097,7413.247 86.122,7413.234 87.029,7413.87 C87.029,7413.87 87.684,7414.185 88.139,7415.37 C88.139,7415.37 88.726,7417.2 91.508,7416.58 C91.513,7417.437 91.522,7418.245 91.522,7418.489 C91.522,7418.76 91.338,7419.077 90.839,7418.982 C86.865,7417.627 84,7413.783 84,7409.253 C84,7403.59 88.478,7399 94,7399" id="github-[#142]"> </path> </g> </g> </g> </g></svg>
               </a>
               <a href={twitter}  target="_blank">
               <svg fill="#432B84" height="30px" width="30px" version="1.1" id="Layer_1"  viewBox="-143 145 512 512" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M329,145h-432c-22.1,0-40,17.9-40,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V185C369,162.9,351.1,145,329,145z M215.2,361.2c0.1,2.2,0.1,4.5,0.1,6.8c0,69.5-52.9,149.7-149.7,149.7c-29.7,0-57.4-8.7-80.6-23.6c4.1,0.5,8.3,0.7,12.6,0.7 c24.6,0,47.3-8.4,65.3-22.5c-23-0.4-42.5-15.6-49.1-36.5c3.2,0.6,6.5,0.9,9.9,0.9c4.8,0,9.5-0.6,13.9-1.9 C13.5,430-4.6,408.7-4.6,383.2v-0.6c7.1,3.9,15.2,6.3,23.8,6.6c-14.1-9.4-23.4-25.6-23.4-43.8c0-9.6,2.6-18.7,7.1-26.5 c26,31.9,64.7,52.8,108.4,55c-0.9-3.8-1.4-7.8-1.4-12c0-29,23.6-52.6,52.6-52.6c15.1,0,28.8,6.4,38.4,16.6 c12-2.4,23.2-6.7,33.4-12.8c-3.9,12.3-12.3,22.6-23.1,29.1c10.6-1.3,20.8-4.1,30.2-8.3C234.4,344.5,225.5,353.7,215.2,361.2z"></path> </g></svg>
               </a>
               <a href={website}  target="_blank"><svg fill="#432B84" width="30px" height="30px" viewBox="0 0 512 512" id="_x30_1" version="1.1"  ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M418.275,146h-46.667 c-5.365-22.513-12.324-43.213-20.587-61.514c15.786,8.776,30.449,19.797,43.572,32.921C403.463,126.277,411.367,135.854,418.275,146 z M452,256c0,17.108-2.191,33.877-6.414,50h-64.034c1.601-16.172,2.448-32.887,2.448-50s-0.847-33.828-2.448-50h64.034 C449.809,222.123,452,238.892,452,256z M256,452c-5.2,0-21.048-10.221-36.844-41.813c-6.543-13.087-12.158-27.994-16.752-44.187 h107.191c-4.594,16.192-10.208,31.1-16.752,44.187C277.048,441.779,261.2,452,256,452z M190.813,306 c-1.847-16.247-2.813-33.029-2.813-50s0.966-33.753,2.813-50h130.374c1.847,16.247,2.813,33.029,2.813,50s-0.966,33.753-2.813,50 H190.813z M60,256c0-17.108,2.191-33.877,6.414-50h64.034c-1.601,16.172-2.448,32.887-2.448,50s0.847,33.828,2.448,50H66.414 C62.191,289.877,60,273.108,60,256z M256,60c5.2,0,21.048,10.221,36.844,41.813c6.543,13.087,12.158,27.994,16.752,44.187H202.404 c4.594-16.192,10.208-31.1,16.752-44.187C234.952,70.221,250.8,60,256,60z M160.979,84.486c-8.264,18.301-15.222,39-20.587,61.514 H93.725c6.909-10.146,14.812-19.723,23.682-28.593C130.531,104.283,145.193,93.262,160.979,84.486z M93.725,366h46.667 c5.365,22.513,12.324,43.213,20.587,61.514c-15.786-8.776-30.449-19.797-43.572-32.921C108.537,385.723,100.633,376.146,93.725,366z M351.021,427.514c8.264-18.301,15.222-39,20.587-61.514h46.667c-6.909,10.146-14.812,19.723-23.682,28.593 C381.469,407.717,366.807,418.738,351.021,427.514z"></path></g></svg></a>
           </div>
           <style>
            {
              `
              .profile-links{
                display:flex;
                align-items:center;
                justify-content:center;
                gap:14px;
              }
              .profile-links a{
                background:none;
                cursor:pointer;
              }
              .profile-links a:hover{
                background:none;
                cursor:pointer;
              }
              
              `
            }
           </style>
           <p></p>
           <p className="text-center">{about}</p>
           <Spacer size="medium" />
          <Internet
            githubProfile={githubProfile}
            linkedin={linkedin}
            twitter={twitter}
            updateSocials={updateSocials}
            website={website}
          />
           <Spacer size="medium" />
          <About
            about={about}
            currentTheme={theme}
            location={location}
            name={name}
            picture={picture}
            sound={sound}
            keyboardShortcuts={keyboardShortcuts}
            submitNewAbout={submitNewAbout}
            toggleNightMode={toggleNightMode}
            toggleSoundMode={toggleSoundMode}
            toggleKeyboardShortcuts={toggleKeyboardShortcuts}
            username={username}
            cohort={cohort}
          />
          <Spacer size="medium" />
          <Privacy />
          <Spacer size="medium" />
          <Email
            email={email}
            isEmailVerified={isEmailVerified}
            sendQuincyEmail={sendQuincyEmail}
            updateQuincyEmail={updateQuincyEmail}
          />
          <Spacer size="medium" />

          <Spacer size="medium" />
          <Honesty isHonest={isHonest} updateIsHonest={updateIsHonest} />
          <Spacer size="medium" />
          <Certification
            completedChallenges={completedChallenges}
            createFlashMessage={createFlashMessage}
            is2018DataVisCert={is2018DataVisCert}
            isApisMicroservicesCert={isApisMicroservicesCert}
            isBackEndCert={isBackEndCert}
            isDataAnalysisPyCertV7={isDataAnalysisPyCertV7}
            isDataVisCert={isDataVisCert}
            isCollegeAlgebraPyCertV8={isCollegeAlgebraPyCertV8}
            isFoundationalCSharpCertV8={isFoundationalCSharpCertV8}
            isFrontEndCert={isFrontEndCert}
            isFrontEndLibsCert={isFrontEndLibsCert}
            isFullStackCert={isFullStackCert}
            isHonest={isHonest}
            isInfosecCertV7={isInfosecCertV7}
            isInfosecQaCert={isInfosecQaCert}
            isJsAlgoDataStructCert={isJsAlgoDataStructCert}
            isMachineLearningPyCertV7={isMachineLearningPyCertV7}
            isQaCertV7={isQaCertV7}
            isRelationalDatabaseCertV8={isRelationalDatabaseCertV8}
            isRespWebDesignCert={isRespWebDesignCert}
            isSciCompPyCertV7={isSciCompPyCertV7}
            isJsAlgoDataStructCertV8={isJsAlgoDataStructCertV8}
            username={username}
            verifyCert={verifyCert}
            isEmailVerified={isEmailVerified}
            about={about}
            name={name}
          />

          {userToken && (
            <>
              <Spacer size="medium" />
              <UserToken />
            </>
          )}
          <Spacer size="medium" />
          <DangerZone />
        </main>
      </section>
    </>
  );
}

ShowSettings.displayName = "ShowSettings";

export default connect(mapStateToProps, mapDispatchToProps)(ShowSettings);
