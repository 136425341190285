import Loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { isLanding } from "../../../utils/path-parsers";
import { Link, SkeletonSprite } from "../../helpers";
import { SEARCH_EXPOSED_WIDTH } from "../../../../config/misc";
import NavLinks, { type NavLinksProps } from "./nav-links";
import NavLogo from "./nav-logo";
import burger from "./burgerblack.svg";
import "./universal-nav.css";
import AuthOrProfile from "./auth-or-profile";
import icon from "./icon-forum.svg";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isSignedInSelector } from "../../../redux/selectors";
import Login from "./login"; // Assuming the Login component is in the same directory

const SearchBar = Loadable(() => import("../../search/searchBar/search-bar"));
const SearchBarOptimized = Loadable(
  () => import("../../search/searchBar/search-bar-optimized")
);

type UniversalNavProps = Omit<
  NavLinksProps,
  "toggleNightMode" | "openSignoutModal"
> & {
  fetchState: { pending: boolean };
  searchBarRef?: React.RefObject<HTMLDivElement>;
  isSignedIn?: boolean; // Add isSignedIn prop
};

const UniversalNav = ({
  displayMenu,
  showMenu,
  hideMenu,
  menuButtonRef,
  searchBarRef,
  user,
  fetchState,
  openSignoutModal,
  isSignedIn, // Add this prop
}: UniversalNavProps): JSX.Element => {
  const { pending } = fetchState;
  const { t } = useTranslation();
  const isSearchExposedWidth = useMediaQuery({
    query: `(min-width: ${SEARCH_EXPOSED_WIDTH}px)`,
  });

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for the toggle

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 20);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : "/";

  const search =
    typeof window !== "undefined" && isLanding(currentPath) ? (
      <SearchBarOptimized innerRef={searchBarRef} />
    ) : (
      <SearchBar innerRef={searchBarRef} />
    );

  // Function to toggle the menu
  const handleOpenHamburger = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle between true and false
  };

  // Function to close the menu when a link is clicked
  const handleCloseHamburger = () => {
    setIsMenuOpen(false); // Close the menu
  };

  return (
    <nav
      aria-label={t("aria.primary-nav")}
      className="universal-nav"
      id="universal-nav"
      data-playwright-test-label="header-universal-nav"
    >
      <Link
        className="universal-nav-logo"
        id="universal-nav-logo"
        to="/"
        data-playwright-test-label="header-universal-nav-logo"
      >
        <NavLogo />
      </Link>
      <div className="header-links-new">
        <Link to="/" className={currentPath === "/" ? "active" : ""}>
          Curriculum
        </Link>
       
        <a
          href="https://discord.gg/z4NwQjyn"
          target="_blank"
          className={currentPath === "/forum" ? "active" : ""}
        >
          Forum  <img src={icon} alt="" />
        </a>
      </div>

      <style>
        {
          `
          .header-links-new a:hover{
          color:black;
          }
           
          `
        }
      </style>
      <div className="header-links-new-right main-nav">
        <AuthOrProfile user={user} />
        <NavLinks
          displayMenu={displayMenu}
          hideMenu={hideMenu}
          menuButtonRef={menuButtonRef}
          showMenu={showMenu}
          user={user}
          openSignoutModal={openSignoutModal}
        />
      </div>
      <img
        src={burger}
        alt="Menu"
        className="hamburger-button"
        onClick={handleOpenHamburger} // Call the toggle function
      />

      <div className={`humberg-menu ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li className={currentPath === "/" ? "active" : ""}>
            <Link to="/" onClick={handleCloseHamburger}>
              Curriculum
            </Link>
          </li>

        
          <li className={currentPath === "/forum" ? "active" : ""}>
            <a
              href="https://discord.gg/avC7Bm2k"
              target="_blank"
              onClick={handleCloseHamburger}
            >
              Forum <img src={icon} alt="" />
            </a>
          </li>
          {/* Conditional rendering based on whether the user is signed in */}
          {isSignedIn ? (
            <>
              <li onClick={handleCloseHamburger}>
                <NavLinks
                  displayMenu={displayMenu}
                  hideMenu={hideMenu}
                  menuButtonRef={menuButtonRef}
                  showMenu={showMenu}
                  user={user}
                  openSignoutModal={openSignoutModal}
                />
              </li>
              <li onClick={handleCloseHamburger}>
                <AuthOrProfile user={user} />
              </li>
            </>
          ) : (
            <>
              <li>
                <Login
                  data-test-label="header-sign-in-button"
                  onClick={handleCloseHamburger}
                />
              </li>
             
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

UniversalNav.displayName = "UniversalNav";

const mapStateToProps = createSelector(isSignedInSelector, (isSignedIn) => ({
  isSignedIn,
}));

export default connect(mapStateToProps)(UniversalNav);
